import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col, Card, CardBody,
} from 'reactstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import axios from 'axios';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const ForgotPasswordModal = ({
                               color, btn, title, colored, header, rtl, name, className,
                               handleSubmit,
                             }) => {
  const [modal, setModal] = useState(false);
  const [file, setFiles] = useState(null);
  const [show, setShow] = useState(true);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  const handleShow = () => setShow(false);
  const history = useHistory();


  const [data, setdata] = useState({
    name: '',
    username: '',
    password: '',
    resetpassword: '',
  });


  const toggle = () => {
    setModal(prevState => !prevState);
    setShow(prevState => !prevState);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  function swalErrorFunction() {
    Swal.fire(
      'Error',
      'Something went wrong :(',
    );
  }

  function onSubmit() {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('resetpassword', data.resetpassword);
    axios.post(`${configs.config.SERVER_URI}user/resetpassword`, formData)
      .then((response) => {
        Swal.fire({
          title: 'Success',
          text: 'Notification Sent successfully :)',
          icon: 'success',
        });
        window.location = `${configs.config.SERVER_URI}usermanagement/forgotpassword`;
      })
      .catch(swalErrorFunction);
  }

  const onChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Col md={6} lg={6} xl={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    className="form-control"
                    component="input"
                    type="text"
                    placeholder="Enter Name"
                    onChange={onChange}
                    value={data.name}
                    required
                  />
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Username</span>
                <div className="form__form-group-field">
                  <Field
                    name="username"
                    className="form-control"
                    component="input"
                    type="text"
                    placeholder="Enter Username"
                    onChange={onChange}
                    value={data.username}
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Old password</span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    className="form-control"
                    component="input"
                    type={isPasswordShown ? 'text' : 'password'}
                    onChange={onChange}
                    value={data.password}
                    placeholder="Enter Password"
                    required
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">New password</span>
                <div className="form__form-group-field">
                  <Field
                    name="resetpassword"
                    className="form-control"
                    component="input"
                    type={isPasswordShown ? 'text' : 'password'}
                    onChange={onChange}
                    value={data.resetpassword}
                    placeholder="Enter New Password"
                    required
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <ButtonToolbar className="modal__footer float-right">
                &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                <Button color="primary" type="submit">Send</Button>
              </ButtonToolbar>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

ForgotPasswordModal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

ForgotPasswordModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(ForgotPasswordModal);
