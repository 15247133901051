import React from 'react';
import {
  Col, Container, Row, Card, CardBody,
} from 'reactstrap';
import ForgotPasswordModal from './components/ForgotPasswordModal';

const Notification = () => {
  console.log('sadyffydygads');

  return (
    <Container>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <h3 className="page-title">&nbsp;&nbsp;Change Password</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <ForgotPasswordModal />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Notification;
