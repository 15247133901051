import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import { useHistory } from 'react-router-dom';
import axios from 'axios/index';
import Swal from 'sweetalert2';
import configs from '../../config/config';

const ModalComponent = ({
                          color, btn, title, message, colored, header, rtl, data,
                        }) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();


  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  function swalFunction(e) {
    Swal.fire({
      icon: 'success',
      title: 'Success....',
      text: 'Deleted Successfully',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
          window.location.reload();
      }
    });
  }

  function deleteFunction() {
    const params = new URLSearchParams();

    params.append('id', data);

    axios.post(`${configs.config.SERVER_URI}config/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/config`);
      });
    axios.post(`${configs.config.SERVER_URI}role/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/role`);
      });
    axios.post(`${configs.config.SERVER_URI}publicfeedback/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/feedback`);
      });
    axios.post(`${configs.config.SERVER_URI}appnotification/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/notification`);
      });
    axios.post(`${configs.config.SERVER_URI}place/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/place`);
      });
    axios.post(`${configs.config.SERVER_URI}routerequest/delete`, params)
      .then((response) => {
        swalFunction(`${configs.config.SERVER_URI}usermanagement/routerequest`);
      })
      .catch(error => console.log(error));
  }

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      <Button color={color} onClick={toggle} data={data}>{btn}</Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        id="modal-dialog-delete"

      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn text-lg-right"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <center>{header ? '' : Icon}</center>
          <center><h4 className="text-modal  modal__title">{title}</h4></center>
        </div>
        <center>
          <div className="modal__body">
            {message}
          </div>
        </center>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" outline={colored} color={color} onClick={deleteFunction}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  data: PropTypes.number.isRequired,
};

ModalComponent.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default connect(state => ({
  rtl: state.rtl,
}))(ModalComponent);
