import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import axios from 'axios';
import configs from '../../../config/config';

const ConfigModal = ({
                       color, btn, title, colored, header, rtl, name, className,
                       handleSubmit,
                     }) => {
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const [config, setconfig] = useState({
    name: '',
    value: '',
  });

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  function swalErrorFunction() {
   console.log('error');
  }


  function onSubmit() {
    const params = new URLSearchParams();
    params.append('name', config.name);
    params.append('value', config.value);
    axios.post(`${configs.config.SERVER_URI}config/add`, params)
      .then((response) => {
        Swal.fire({
          title: 'Success',
          text: 'Config Added Successfully :)',
          icon: 'success',
        });
          // history.push('config');
          window.location.reload();
      })
      .catch(swalErrorFunction);
  }

  const onChange = (e) => {
    setconfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color="primary" onClick={toggle}>{title}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-config"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Name"
                        onChange={onChange}
                        value={config.name}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Value</span>
                    <div className="form__form-group-field">
                      <Field
                        name="value"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Value"
                        onChange={onChange}
                        value={config.value}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit">Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

ConfigModal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

ConfigModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(ConfigModal);
