import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Col, Button, ButtonToolbar, Row,
} from 'reactstrap';
import renderDropZoneField from '@/shared/components/form/DropZone';
import axios from 'axios';
import Swal from 'sweetalert2';
import configs from '../../../../config/config';

const ProfileUpload = ({ handleSubmit, user, userid }) => {
  const [profile, setprofilepic] = useState({
    profilepic: '',
    preview: '',
  });

  function loadProfilePic(profileimage) {
    setprofilepic({
      preview: `${configs.config.SERVER_URI}api/media/user/${profileimage}`,
      profilepic: profileimage,
    });
  }

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('id', userid);
    axios.post(`${configs.config.SERVER_URI}user/profileinfo`, params)
      .then((result) => {
        loadProfilePic(result.data.profilepic);
      });
  }, []);


  function handleChange(profileimage) {
    setprofilepic({
      preview: `${profile.preview}`,
      profilepic: profileimage[0],
    });
  }

  function uploadFile(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', userid);
    formData.append('photo', profile.profilepic);
    fetch(`${configs.config.SERVER_URI}user/updateprofilepicture`, {
      mode: 'no-cors',
      method: 'POST',
      body: formData,
    })
      .then((result) => {
        Swal.fire({
          title: 'Success',
          text: 'Profile Picture updated successfully :)',
          icon: 'success',
        });
        loadProfilePic(result.data);
        window.location.reload();
      });
  }


  return (
    <Row>
      <Col md={12} lg={12}>
        <div>
          <form className="form" onSubmit={uploadFile}>
            <Col md={12} lg={12}>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <img src={profile.preview} alt="Please wait Uploading user profile..." className="notification-image" />
                  <Field
                    name="photo"
                    component={renderDropZoneField}
                    type="files"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
            <ButtonToolbar className="form__button-toolbar float-right">
              <Button color="primary" type="submit">Update</Button>
            </ButtonToolbar>
          </form>
        </div>
      </Col>
    </Row>
  );
};

ProfileUpload.propTypes = {
  userid: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.string,
};
ProfileUpload.defaultProps = {
  user: {},
};
export default reduxForm({
  form: 'file_upload_custom_height', // a unique identifier for this form
})(ProfileUpload);
