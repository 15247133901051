import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import RoleDataTable from './components/RoleDataTable';
import RoleData from './components/RoleData';

const Config = () => {
  const roleDataTable = RoleData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Role Information</h3>
        </Col>
      </Row>
      <Row>
        <RoleDataTable roleDataTable={roleDataTable} />
      </Row>
    </Container>
  );
};

export default Config;
