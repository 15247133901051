import React, { useMemo, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import FeedbackViewModal from './FeedbackViewModal';
import configs from '../../../config/config';

const FeedbackData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}publicfeedback/get`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Title',
        id: 'title',
        accessor: d => d.title,
      },
      {
        Header: 'Message',
        id: 'message',
        accessor: d => d.message,
        disableGlobalFilter: true,
      },
      {
        Header: 'Ratings',
        id: 'ratings',
        accessor: d => d.ratings,
      },
      {
        Header: 'Phone',
        id: 'phone',
        accessor: d => d.phone,
        disableGlobalFilter: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );


  const datalist = [];

  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        title: data[i].title,
        message: data[i].message,
        ratings: data[i].ratings,
        phone: data[i].phone,
        action: [
          <Row>
            <FeedbackViewModal
              btncolor="warning"
              color="primary"
              btntitle="View"
              title="View Feedback"
              header
              btn="primary"
              dataid={data[i].id}
              datatitle={data[i].title}
              datamessage={data[i].message}
              dataphone={data[i].phone}
              dataratings={data[i].ratings}
            />&nbsp; &nbsp;
            <Modal
              color="danger"
              title="Delete"
              btn="Delete"
              message="Are you sure you want to delete this"
              data={data[i].id}
            />
          </Row>],
      });
    }
  };

  rows();
  const feedbackDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return feedbackDataTable;
};
export default FeedbackData;


