import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { UserProps, AuthOProps } from '@/shared/prop-types/ReducerProps';
import { hookAuth0 } from '@/shared/components/auth/withAuth0';
import TopbarMenuLink from './TopbarMenuLink';
import configs from '../../../config/config';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({ auth0, user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const history = useHistory();

  const logout = () => {
    localStorage.setItem('id', '');
    history.push('/');
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(auth0.user && auth0.user.picture) || (`${configs.config.SERVER_URI}api/media/user/${user.profilepic}`) || Ava}
          alt="avatar"
        />
        <p className="topbar__avatar-name">
          { auth0.loading ? 'Loading...' : (auth0.user && auth0.user.name) || (user && user.fullName)}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="My Profile"
            icon="user"
            path={`/usermanagement/profile/${user.id}`}
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          {auth0.isAuthenticated && (
            <TopbarMenuLink
              title="Log Out Auth0"
              icon="exit"
              path="/log_in"
              onClick={auth0.logout}
            />
          )}
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            path="/"
            onClick={logout}
          />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps,
  auth0: AuthOProps.isRequired,
};

TopbarProfile.defaultProps = {
  user: {},
};

export default hookAuth0(TopbarProfile);
