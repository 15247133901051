import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col, Card, CardBody,
} from 'reactstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import axios from 'axios';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const NotificationModal = ({
                             color, btn, title, colored, header, rtl, name, className,
                             handleSubmit,
                           }) => {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(true);

  const handleShow = () => setShow(false);
  const history = useHistory();


  const [notification, setnotification] = useState({
    title: '',
    message: '',
    receiverType: '',
    photo: '',
  });


  const toggle = () => {
    setModal(prevState => !prevState);
    setShow(prevState => !prevState);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  function swalErrorFunction() {
    Swal.fire(
      'Error',
      'Something went wrong :(',
    );
  }

  function onSubmit() {
    const formData = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    if (imagedata != null) {
      formData.append('photo', imagedata);
    }
    formData.append('title', notification.title);
    formData.append('message', notification.message);
    formData.append('receiverType', notification.receiverType);
    axios.post(`${configs.config.SERVER_URI}appnotification/add`, formData)
      .then((response) => {
        Swal.fire({
          title: 'Success',
          text: 'Notification Sent successfully :)',
          icon: 'success',
        });
        // history.push('notification');
          window.location.reload();
      })
      .catch(swalErrorFunction);
  }

  const onChange = (e) => {
    setnotification({
      ...notification,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Col md={6} lg={6} xl={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    className="form-control"
                    component="input"
                    type="text"
                    placeholder="Enter Title"
                    onChange={onChange}
                    value={notification.title}
                    required
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Message</span>
                <div className="form__form-group-field">
                  <Field
                    name="message"
                    className="form-control"
                    component="textarea"
                    type="text"
                    onChange={onChange}
                    value={notification.message}
                    placeholder="Enter Message"
                    required
                  />
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Upload photo</span>
                <div className="form__form-group-field">
                  <Field
                    id="photo"
                    name="photo"
                    type="file"
                    component={renderDropZoneField}
                  />
                </div>
              </div>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <ButtonToolbar className="modal__footer float-right">
                &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                <Button color="primary" type="submit">Send</Button>
              </ButtonToolbar>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

NotificationModal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

NotificationModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(NotificationModal);
