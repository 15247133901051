import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import ProfileSettings from './ProfileSettings';
import ProfilePassword from './ProfilePassword';
import ProfilePic from './ProfilePhoto';
import configs from '../../../../config/config';

let tmp = [];

const ProfileTabs = ({ userid, username }) => {
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('id', userid);

    axios.post(`${configs.config.SERVER_URI}user/profileinfo`, params)
      .then((result) => {
        tmp = result.data;
      });
  }, []);

  const initialValues = {
    name: tmp.name,
    username: tmp.username,
    email: tmp.email,
    mobile: tmp.phone,
    address: tmp.address,
    note: tmp.note,
    imei: tmp.imei,
  };

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Basic Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => handleToggle('2')}
                >
                  Password Update
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => handleToggle('3')}
                >
                  Profile Update
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ProfileSettings initialValues={initialValues} userid={userid} />
              </TabPane>
              <TabPane tabId="2">
                <ProfilePassword initialValues={initialValues} userid={userid} />
              </TabPane>
              <TabPane tabId="3">
                <ProfilePic userid={userid} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

ProfileTabs.propTypes = {
  userid: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default ProfileTabs;
