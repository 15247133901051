import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import configs from '../../../../config/config';

// let tmp = [];
let inputCheck;
const ProfileMain = ({ userid }) => {
  const [profile, setprofilepic] = useState({
    preview: '',
  });

  function checkActiveUser() {
    const params = new URLSearchParams();
    params.append('id', userid);
    params.append('status', 'false');

    axios.post(`${configs.config.SERVER_URI}user/active`, params)
      .then((result) => {
        setprofilepic(result.data);
        inputCheck = <input id="statuscheck" type="checkbox" value="on" checked onClick={checkActiveUser} />;
      })
      .catch(error => console.log(error));
  }

  function checkInActiveUser() {
    const params = new URLSearchParams();
    params.append('id', userid);
    params.append('status', 'true');

    axios.post(`${configs.config.SERVER_URI}user/active`, params)
      .then((result) => {
        setprofilepic(result.data);
        inputCheck = <input className="activeornot" type="checkbox" value="off" onClick={checkInActiveUser} />;
      })
      .catch(error => console.log(error));
  }

  function loadProfilePic(profileimage) {
    setprofilepic({
      preview: `${configs.config.SERVER_URI}api/media/user/${profileimage}`,
    });
  }

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('id', userid);
    axios.post(`${configs.config.SERVER_URI}user/profileinfo`, params)
      .then((result) => {
        loadProfilePic(result.data.profilepic);
        setprofilepic(result.data);
        if (result.data.active === true) {
          inputCheck = <input className="activeornot" type="checkbox" checked onClick={checkActiveUser} />;
        }
        if (result.data.active === false) {
          inputCheck = <input className="activeornot" type="checkbox" onClick={checkInActiveUser} />;
        }
      });
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={`${configs.config.SERVER_URI}api/media/user/${profile.profilepic}`} alt="" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{profile.name}</p>
              <p className="profile__work">{profile.mobile}</p>
              <p className="profile__contact">{profile.email}</p>
              <p className="profile__contact" dir="ltr">{profile.address}</p>
              <p>Active</p>
              {inputCheck}
            </div>
          </div>


        </CardBody>
      </Card>
    </Col>
);
};

ProfileMain.propTypes = {
  userid: PropTypes.string.isRequired,
};

export default ProfileMain;
