import React, { useState, useEffect } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { auth } from '@/redux/actions/authActions';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import axios from 'axios';
import configs from '../../../config/config';
import Captcha from '../../../containers/Account/LogIn/captcha';

const LogInForm = ({
                     handleSubmit, errorMessage, errorMsg, fieldUser, typeFieldUser, form, auth: updateUser,
                   }) => {
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const url = `${configs.config.SERVER_URI}captcha`;
  const [login, setlogin] = useState({
    username: '',
    password: '',
    captcha: '',
  });


  const onChange = (e) => {
    setlogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  function swalFunction() {
    Swal.fire(
      'Error',
      'Your username or password or seurity code is incorrect :(',
      'error',
    );
  }


  function submitFunction(e) {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append('username', login.username);
    params.append('password', login.password);
    axios.post(`${configs.config.SERVER_URI}login`, params)
      .then((response) => {
        const userData = response.data;
        localStorage.setItem('id', userData.id);
        updateUser(userData);
        history.push('transit_dashboard');
      })
      .catch(error => swalFunction());
  }

  return (
    <Form className="form login-form" onSubmit={submitFunction} autoComplete="off">
      <Alert
        color="danger"
        isOpen={!!errorMessage || !!errorMsg}
      >
        {errorMessage}
        {errorMsg}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">{fieldUser}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="username"
            component="input"
            type={typeFieldUser}
            placeholder={fieldUser}
            className="input-without-border-radius"
            onChange={onChange}
            value={login.username}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="input-without-border-radius"
            onChange={onChange}
            value={login.password}
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={showPasswordToggle}
          ><EyeIcon />
          </button>
        </div>
      </div>
      { /*
      <Captcha />
      */ }
      <br />
      <br />
      <div className="account__btns">
        {
          form === 'modal_login'
            ? <Button className="account__btn" type="submit" color="primary">Log In</Button>
            : (
              <Link className="account__btn btn btn-primary" from="/log_in" to="/dashboard">
                Log In
              </Link>
            )
        }

      </div>
    </Form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
  auth: PropTypes.func.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}), { auth })(reduxForm()(LogInForm));
