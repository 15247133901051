import React from 'react';
import PropTypes from 'prop-types';
import withAuthFirebase from '@/shared/components/auth/withAuthFirebase';
import { useAuth0 } from '@/shared/components/auth/withAuth0';
import Loading from '@/shared/components/Loading';
import LogInForm from '@/shared/components/loginForm/LogInForm';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function Copyright() {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright � '}{new Date().getFullYear()}
        <Link color="blue" href="https://capulustech.com/" target="_blank"> Capulus Technologies</Link>{' '}{'.'}
      </Typography>
    </div>
  );
}


const LogIn = ({ changeIsOpenModalFireBase }) => {
  const {
    loginWithRedirect, loading,
  } = useAuth0();
  if (loading) {
    return (<Loading loading={loading} />);
  }

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Welcome to
              <span className="account__logo"> Transit
              </span>
            </h3>
            <br />
          </div>
          <LogInForm
            form="modal_login"
          />
          <Box mt={2}>
            <Copyright />
          </Box>

        </div>
      </div>
    </div>
  );
};

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
