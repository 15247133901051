import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import SosDataTable from './components/SosDataTable';
import SosData from './components/SosData';

const Config = () => {
  const sosDataTable = SosData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">SOS Information</h3>
        </Col>
      </Row>
      <Row>
        <SosDataTable sosDataTable={sosDataTable} />
      </Row>
    </Container>
  );
};

export default Config;
