import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import axios from 'axios/index';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const renderConfigField = ({
                             input, type, placeholder, value,
                           }) => (
                             <MaskedInput {...input} placeholder={placeholder} type={type} value={value} />
);

renderConfigField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

renderConfigField.defaultProps = {
  placeholder: '',
  type: 'text',
};

const ConfigEditForm = ({
                          color, btncolor, btn, title, btntitle, colored, header, name, className,
                          handleSubmit, dataid, dataname, datavalue,
                        }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [config, setconfig] = useState({
    name: dataname,
    value: datavalue,
  });
  console.log(config);

  function swalFunction() {
    Swal.fire({
      icon: 'success',
      title: 'Success....',
      text: 'Config successfully updated',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
  }

  function submitFunction() {
    const params = new URLSearchParams();
    params.append('id', dataid);
    params.append('name', config.name);
    params.append('value', config.value);
    axios.post(`${configs.config.SERVER_URI}config/update`, params)
      .then((response) => {
        swalFunction();
      })
      .catch(error => console.log(error));
  }

  let Icon;

  switch ([color, btncolor]) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });
  const onChange = (e) => {
    setconfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color={btncolor} onClick={toggle}>{btntitle}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-config"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <input
                        name="name"
                        className="form-control"
                        component={renderConfigField}
                        type="text"
                        value={config.name}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Value</span>
                    <div className="form__form-group-field">
                      <input
                        name="value"
                        className="form-control"
                        component={renderConfigField}
                        type="text"
                        value={config.value}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit" onClick={submitFunction}>Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

ConfigEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  btntitle: PropTypes.string,
  color: PropTypes.string.isRequired,
  btncolor: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  dataid: PropTypes.string.isRequired,
  dataname: PropTypes.string.isRequired,
  datavalue: PropTypes.string.isRequired,
};

ConfigEditForm.defaultProps = {
  title: '',
  btntitle: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};

const configEditForm = reduxForm({
  enableReinitialize: true,
  form: 'horizontal_form',
})(ConfigEditForm);

const mapStateToProps = state => ({
  initialValues: state.cryptoTable.data,
});

export default connect(mapStateToProps)(configEditForm);
