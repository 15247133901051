import React, { useMemo, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import PlaceEditModal from './PlaceEditModal';
import configs from '../../../config/config';

const PlaceData = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}place/get`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
      },
      {
        Header: 'Stop Name',
        id: 'stop_name',
        accessor: d => d.stop_name,
      },
      {
        Header: 'Route Name',
        id: 'route_short_name',
        accessor: d => d.route_short_name,
      },
      {
        Header: 'Location',
        accessor: 'location',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const datalist = [];

  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        name: data[i].name,
        stop_name: data[i].stop_name,
        route_short_name: data[i].route_short_name,
        location: <a href={`https://www.google.com/maps?q=${data[i].lat},${data[i].lng}`} target="_blank" rel="noreferrer"> {data[i].lat},{data[i].lng}</a>,
        action: [
          <Row>
            <PlaceEditModal
              btncolor="warning"
              color="primary"
              btntitle="Edit"
              title="Edit Place"
              header
              btn="primary"
              dataid={data[i].id}
              dataname={data[i].name}
              dataaddress={data[i].address}
              datalat={data[i].lat}
              datalng={data[i].lng}
              datadescription={data[i].description}
              datastopid={data[i].stop_id}
              datastopname={data[i].stop_name}
              datarouteshortname={data[i].route_short_name}
              dataphotos={data[i].photos}

            />
            <Modal
              color="danger"
              title="Delete"
              btn="Delete"
              message="Are you sure you want to delete this"
              data={data[i].id}
            />
          </Row>],
      });
    }
  };

  rows();
  const placeDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return placeDataTable;
};
export default PlaceData;


