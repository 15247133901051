import React from 'react';
import { Route, Switch } from 'react-router-dom';
import User from '../../../User/user-index';
import Config from '../../../Config/config-index';
import Profile from '../../../Account/Profile';
import Notification from '../../../Notification/Notification-index';
import Place from '../../../Place/Place-index';
import Feedback from '../../../Feedback/feedback-index';
import Role from '../../../Role/role-index';
import RouteRequest from '../../../RouteRequest/routerequest-index';
import SOS from '../../../SOS/sos-index';
import ForgotPassword from '../../../ForgotPassword/ForgotPassword-index';

export default () => (
  <Switch>
    <Route path="/usermanagement/user" component={User} />
    <Route path="/usermanagement/config" component={Config} />
    <Route path="/usermanagement/profile/:id"><Profile /></Route>
    <Route path="/usermanagement/notification" component={Notification} />
    <Route path="/usermanagement/feedback" component={Feedback} />
    <Route path="/usermanagement/place" component={Place} />
    <Route path="/usermanagement/role" component={Role} />
    <Route path="/usermanagement/routerequest" component={RouteRequest} />
    <Route path="/usermanagement/sos" component={SOS} />
    <Route path="/usermanagement/forgotpassword" component={ForgotPassword} />
  </Switch>
);
