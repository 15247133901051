import React, { useMemo, useState, useEffect } from 'react';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import configs from '../../../config/config';

const RouteRequestData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}routerequest/get`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'From Address',
        id: 'fromAddress',
        accessor: d => d.fromAddress,
      },
      {
        Header: 'To Address',
        id: 'toAddress',
        accessor: d => d.toAddress,
      },
      {
        Header: 'Location',
        accessor: 'toLocation',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Time',
        id: 'time',
        accessor: d => d.time,
        disableGlobalFilter: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );


  const datalist = [];
  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        fromAddress: data[i].fromAddress,
        toAddress: data[i].toAddress,
        toLocation:
  <a href={`https://www.google.com/maps?q=${data[i].fromLocation},${data[i].toLocation}`} target="_blank" rel="noreferrer">{data[i].fromLocation},{data[i].toLocation}</a>,
        time: data[i].time.substring(11, 16),
        action: <Modal
          color="danger"
          title="Delete"
          btn="Delete"
          message="Are you sure you want to delete this"
          data={data[i].id}
        />,
      });
    }
  };

  rows();
  const routerequestDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return routerequestDataTable;
};
export default RouteRequestData;


