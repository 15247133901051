import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR_AUTH,
} from '../actions/authActions';

const initialState = {
  fullName: '',
  profilepic: 'NA',
  id: '',
  username: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        fullName: action.user.name,
        username: action.user.username,
        profilepic: action.user.profilepic,
        id: action.user.id,
      };
    case AUTHENTICATE_ERROR_AUTH:
      return { error: action.error };
    default:
      return state;
  }
};

export default authReducer;
