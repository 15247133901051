import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import renderDropZoneField from '@/shared/components/form/DropZone';
import MaskedInput from 'react-text-mask';
import axios from 'axios/index';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const renderPlaceField = ({
                            input, type, placeholder, value,
                          }) => (
                            <MaskedInput {...input} placeholder={placeholder} type={type} value={value} />
);

renderPlaceField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

renderPlaceField.defaultProps = {
  placeholder: '',
  type: 'text',
};

const PlaceEditForm = ({
                         color, btncolor, btn, title, btntitle, colored, header, name, className,
                         handleSubmit, dataid, dataname, dataaddress, datalat, datalng,
                         datadescription, datastopid, datastopname, datarouteshortname, dataphotos,
                       }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [place, setplace] = useState({
    name: dataname,
    address: dataaddress,
    lat: datalat,
    lng: datalng,
    description: datadescription,
    stop_id: datastopid,
    stop_name: datastopname,
    route_short_name: datarouteshortname,
    photos: dataphotos,
    preview: `${configs.config.SERVER_URI}api/media/place/${dataphotos}`,
  });

  function swalFunction() {
    Swal.fire({
      icon: 'success',
      title: 'Success....',
      text: 'Place Updated Successfully',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  function submitFunction() {
    const formData = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    if (imagedata != null) {
      formData.append('photos', imagedata);
    }
    formData.append('id', dataid);
    formData.append('name', place.name);
    formData.append('lat', place.lat);
    formData.append('lng', place.lng);
    formData.append('description', place.description);
    formData.append('stop_id', place.stop_id);
    formData.append('stop_name', place.stop_name);
    formData.append('route_short_name', place.route_short_name);
    formData.append('address', place.address);
    axios.post(`${configs.config.SERVER_URI}place/update`, formData)
      .then((response) => {
        swalFunction();
      })
      .catch(error => console.log(error));
  }

  let Icon;

  switch ([color, btncolor]) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });
  const onChange = (e) => {
    setplace({
      ...place,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color={btncolor} onClick={toggle}>{btntitle}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-feedback"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <input
                        name="name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Name"
                        onChange={onChange}
                        value={place.name}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Latitude</span>
                    <div className="form__form-group-field">
                      <input
                        name="lat"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Latitude"
                        onChange={onChange}
                        value={place.lat}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Longitude</span>
                    <div className="form__form-group-field">
                      <input
                        name="lng"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Longitude"
                        onChange={onChange}
                        value={place.lng}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Stop Id</span>
                    <div className="form__form-group-field">
                      <input
                        name="stop_id"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Stop Id"
                        onChange={onChange}
                        value={place.stop_id}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Stop Name</span>
                    <div className="form__form-group-field">
                      <input
                        name="stop_name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Stop Name"
                        onChange={onChange}
                        value={place.stop_name}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Route Name</span>
                    <div className="form__form-group-field">
                      <input
                        name="route_short_name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Route Name"
                        onChange={onChange}
                        value={place.route_short_name}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address</span>
                    <div className="form__form-group-field">
                      <textarea
                        name="address"
                        className="form-control"
                        type="text"
                        onChange={onChange}
                        value={place.address}
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <textarea
                        name="description"
                        className="form-control"
                        type="text"
                        onChange={onChange}
                        value={place.description}
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Upload photo</span>
                    <div className="form__form-group-field">
                      <img src={place.preview} alt="" className="place-image" />
                      <Field
                        id="photos"
                        name="photos"
                        type="file"
                        className="place-image"
                        component={renderDropZoneField}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit" onClick={submitFunction}>Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

PlaceEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  btntitle: PropTypes.string,
  color: PropTypes.string.isRequired,
  btncolor: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  dataid: PropTypes.string.isRequired,
  dataname: PropTypes.string.isRequired,
  dataaddress: PropTypes.string.isRequired,
  datalat: PropTypes.string,
  datalng: PropTypes.string,
  datadescription: PropTypes.string,
  datastopid: PropTypes.string,
  datastopname: PropTypes.string,
  datarouteshortname: PropTypes.string,
  dataphotos: PropTypes.string,
};

PlaceEditForm.defaultProps = {
  title: '',
  btntitle: '',
  colored: false,
  header: false,
  name: '',
  className: '',
  datalat: '',
  datalng: '',
  datastopid: '',
  datastopname: '',
  datarouteshortname: '',
  dataphotos: '',
  datadescription: '',
};
const placeEditForm = reduxForm({
  enableReinitialize: true,
  form: 'horizontal_form',
})(PlaceEditForm);

const mapStateToProps = state => ({
  initialValues: state.cryptoTable.data,
});

export default connect(mapStateToProps)(placeEditForm);
