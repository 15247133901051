export default {
  getAppConfigData: () => new Promise((resolve) => {
    const appConfigData = {
      data: [], // array for your app configurations
    };
    console.log('to', appConfigData);
    setTimeout(() => {
      console.log('te', appConfigData);
      resolve({ data: appConfigData });
    }, 1000);
  }),
};
