import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';

const Profile = () => {
  const { id } = useParams();
  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain userid={id} />
            </Row>
          </Col>
          <ProfileTabs userid={id} />
        </Row>
      </div>
    </Container>
  );
};

export default Profile;
