/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import axios from 'axios/index';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';
import configs from '../../../../config/config';

const renderTextField = ({
                           input, label, meta: { touched, error }, children,
                         }) => (
                           <TextField
                             className="material-form__field"
                             label={label}
                             error={touched && error}
                             children={children}
                             value={input.value}
                           />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
};

const ProfileSettings = ({ handleSubmit, reset, userid }) => {
  const [profile, setprofile] = useState({
    name: '',
    username: '',
    phone: '',
    address: '',
    note: '',
    imei: '',
    email: '',
  });

  function userDataInitialize(userData) {
    setprofile({
      ...profile,
      username: userData.username,
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      imei: userData.imei,
      note: userData.note,
      address: userData.address,
    });
  }

  function swalErrorFunction() {
    Swal.fire(
      'Error',
      'Please update correct information :(',
      'error',
    );
  }


  function onSubmit(e) {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append('id', userid);
    params.append('name', profile.name);
    params.append('username', profile.username);
    params.append('email', profile.email);
    params.append('phone', profile.phone);
    params.append('imei', profile.imei);
    params.append('note', profile.note);
    params.append('address', profile.address);

    axios.post(`${configs.config.SERVER_URI}user/update`, params)
      .then((result) => {
        userDataInitialize(result.data);
        Swal.fire(
          'Success',
          'Profile information updated successfully :)',
          'success',
        );
      })
      .catch(error => swalErrorFunction());
  }

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('id', userid);

    axios.post(`${configs.config.SERVER_URI}user/profileinfo`, params)
      .then((result) => {
        userDataInitialize(result.data);
      });
  }, []);

  const onChange = (e) => {
    setprofile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form className="material-form" onSubmit={onSubmit} autoComplete="off">
      <div>
        <span className="material-form__label">Name</span><br />
        <input
          className="form-control"
          id="profilename"
          name="name"
          value={profile.name}
          onChange={onChange}
          required
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">User Name</span>
        <input
          name="username"
          className="form-control"
          component={renderTextField}
          value={profile.username}
          onChange={onChange}
          disabled
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">Email</span>
        <input
          name="email"
          className="form-control"
          component={renderTextField}
          placeholder="example@mail.com"
          type="email"
          value={profile.email}
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">Address</span>
        <input
          name="address"
          className="form-control"
          component={renderTextField}
          placeholder=""
          type="text"
          value={profile.address}
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">Mobile</span>
        <input
          name="phone"
          className="form-control"
          component={renderTextField}
          type="number"
          value={profile.phone}
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">IMEI</span>
        <input
          name="imei"
          className="form-control"
          component={renderTextField}
          type="text"
          value={profile.imei}
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <span className="material-form__label">Note</span>
        <textarea
          name="note"
          className="form-control"
          component={renderTextField}
          placeholder="Type message here"
          multiline
          rowsMax="5"
          value={profile.note}
          onChange={onChange}
        />
      </div>
      <ButtonToolbar className="form__button-toolbar float-right">
        <Button color="primary" type="submit" onClick={onSubmit}>Update</Button>
      </ButtonToolbar>
    </form>
  );
};

ProfileSettings.propTypes = {
  userid: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'profile_settings_form', // a unique identifier for this form
})(ProfileSettings);
