import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import RouteRequestDataTable from './components/RouteRequestDataTable';
import RouteRequestData from './components/RouteRequestData';

const RouteRequest = () => {
  const routerequestDataTable = RouteRequestData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Route Request Information</h3>
        </Col>
      </Row>
      <Row>
        <RouteRequestDataTable routerequestDataTable={routerequestDataTable} />
      </Row>
    </Container>
  );
};

export default RouteRequest;
