import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal, Row, Col, Table,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';

const renderFeedbackField = ({
                               input, type, placeholder,
                             }) => (
                               <MaskedInput {...input} placeholder={placeholder} type={type} />
);

renderFeedbackField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

renderFeedbackField.defaultProps = {
  placeholder: '',
  type: 'text',
};

const FeedbackViewForm = ({
                            color, btncolor, btn, title, btntitle, colored, header, name, className,
                            handleSubmit, dataid, datatitle, datamessage, dataphone, dataratings,
                          }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };


  let Icon;

  switch ([color, btncolor]) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  return (
    <Row>
      <Col md={12} lg={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color={btncolor} onClick={toggle}>{btntitle}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-feedback"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit}>
                <Col md={12} lg={12} xl={12}>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Phone</th>
                        <th>Rating</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{datatitle}</td>
                        <td>{dataphone}</td>
                        <td>{dataratings}</td>
                        <td>{datamessage}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    <Button color="primary" type="button" onClick={toggle}>OK</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

FeedbackViewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  btntitle: PropTypes.string,
  color: PropTypes.string.isRequired,
  btncolor: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  dataid: PropTypes.string.isRequired,
  datatitle: PropTypes.string.isRequired,
  datamessage: PropTypes.string.isRequired,
  dataphone: PropTypes.string.isRequired,
  dataratings: PropTypes.string.isRequired,
};

FeedbackViewForm.defaultProps = {
  title: '',
  btntitle: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};

const feedbackViewForm = reduxForm({
  enableReinitialize: true,
  form: 'horizontal_form',
})(FeedbackViewForm);

const mapStateToProps = state => ({
  initialValues: state.cryptoTable.data,
});

export default connect(mapStateToProps)(feedbackViewForm);
