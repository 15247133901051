import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2/dist/sweetalert2.all.min';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col, Card, CardBody, Badge, Table,
} from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import BasicTableData from './BasicTableData';
import configs from '../../../config/config';

const { tableHeaderData, tableRowsData } = BasicTableData();

const RoleModal = ({
                     color, btn, title, colored, header, rtl, name, className,
                     handleSubmit,
                   }) => {
  const [modal, setModal] = useState(false);

  const [role, setrole] = useState({
    name: '',
    value: '',
  });

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  function HandleError() {
    swal({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Failed to add Role</a>',
    });
  }

  function onSubmit() {
    const params = new URLSearchParams();
    params.append('name', role.name);
    const useradd = document.getElementById('useradd');
    const useredit = document.getElementById('useredit');
    const userdelete = document.getElementById('userdelete');
    const userview = document.getElementById('userview');
    if (useradd.checked) {
      params.append('useradd', useradd.value);
    }
    if (useredit.checked) {
      params.append('useredit', useredit.value);
    }
    if (userdelete.checked) {
      params.append('userdelete', userdelete.value);
    }
    if (userview.checked) {
      params.append('userview', userview.value);
    }
    const feedbackadd = document.getElementById('feedbackadd');
    const feedbackedit = document.getElementById('feedbackedit');
    const feedbackdelete = document.getElementById('feedbackdelete');
    const feedbackview = document.getElementById('feedbackview');
    if (feedbackadd.checked) {
      params.append('feedbackadd', feedbackadd.value);
    }
    if (feedbackedit.checked) {
      params.append('feedbackedit', feedbackedit.value);
    }
    if (feedbackdelete.checked) {
      params.append('feedbackdelete', feedbackdelete.value);
    }
    if (feedbackview.checked) {
      params.append('feedbackview', feedbackview.value);
    }
    const notificationadd = document.getElementById('notificationadd');
    const notificationedit = document.getElementById('notificationedit');
    const notificationdelete = document.getElementById('notificationdelete');
    const notificationview = document.getElementById('notificationview');
    if (notificationadd.checked) {
      params.append('notificationadd', notificationadd.value);
    }
    if (notificationedit.checked) {
      params.append('notificationedit', notificationedit.value);
    }
    if (notificationdelete.checked) {
      params.append('notificationdelete', notificationdelete.value);
    }
    if (notificationview.checked) {
      params.append('notificationview', notificationview.value);
    }
    const placeadd = document.getElementById('placeadd');
    const placeedit = document.getElementById('placeedit');
    const placedelete = document.getElementById('placedelete');
    const placeview = document.getElementById('placeview');
    if (placeadd.checked) {
      params.append('placeadd', placeadd.value);
    }
    if (placeedit.checked) {
      params.append('placeedit', placeedit.value);
    }
    if (placedelete.checked) {
      params.append('placedelete', placedelete.value);
    }
    if (placeview.checked) {
      params.append('placeview', placeview.value);
    }
    const configadd = document.getElementById('configadd');
    const configedit = document.getElementById('configedit');
    const configdelete = document.getElementById('configdelete');
    const configview = document.getElementById('configview');
    if (configadd.checked) {
      params.append('configadd', configadd.value);
    }
    if (configedit.checked) {
      params.append('configedit', configedit.value);
    }
    if (configdelete.checked) {
      params.append('configdelete', configdelete.value);
    }
    if (configview.checked) {
      params.append('configview', configview.value);
    }
    const sosadd = document.getElementById('sosadd');
    const sosedit = document.getElementById('sosedit');
    const sosdelete = document.getElementById('sosdelete');
    const sosview = document.getElementById('placeview');
    if (sosadd.checked) {
      params.append('sosadd', sosadd.value);
    }
    if (sosedit.checked) {
      params.append('sosedit', sosedit.value);
    }
    if (sosdelete.checked) {
      params.append('sosdelete', sosdelete.value);
    }
    if (sosview.checked) {
      params.append('sosview', sosview.value);
    }
    const routerequestadd = document.getElementById('routerequestadd');
    const routerequestedit = document.getElementById('routerequestedit');
    const routerequestdelete = document.getElementById('routerequestdelete');
    const routerequestview = document.getElementById('routerequestview');
    if (routerequestadd.checked) {
      params.append('routerequestadd', routerequestadd.value);
    }
    if (routerequestedit.checked) {
      params.append('routerequestedit', routerequestedit.value);
    }
    if (routerequestdelete.checked) {
      params.append('routerequestdelete', routerequestdelete.value);
    }
    if (routerequestview.checked) {
      params.append('routerequestview', routerequestview.value);
    }
    axios.post(`${configs.config.SERVER_URI}role/add`, params)
      .then((response) => {
          window.location.reload();
      })
      .catch(error => console.log(error));
  }

  const onChange = (e) => {
    setrole({
      ...role,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color="primary" onClick={toggle}>Add Role</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-role"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Enter Name"
                        onChange={onChange}
                        value={role.name}
                        required
                      />
                    </div>
                  </div>
                </Col><hr />
                <Col md={12} lg={12} xl={12}>
                  <div className="form__form-group">
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>Feature names</th>
                          <th>Add Feature</th>
                          <th>Edit Feature</th>
                          <th>Delete Feature</th>
                          <th>View Feature</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>User</td>
                          <td><input type="Checkbox" id="useradd" name="blue" value="USER_ADD" /></td>
                          <td><input type="Checkbox" id="useredit" name="red" value="USER_EDIT" /></td>
                          <td><input type="Checkbox" id="userdelete" name="green" value="USER_DELETE" /></td>
                          <td><input type="Checkbox" id="userview" name="green" value="USER_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>Feedback</td>
                          <td><input type="Checkbox" id="feedbackadd" name="blue" value="PUBLIC_FEEDBACK_ADD" /></td>
                          <td><input type="Checkbox" id="feedbackedit" name="red" value="PUBLIC_FEEDBACK_EDIT" /></td>
                          <td><input type="Checkbox" id="feedbackdelete" name="green" value="PUBLIC_FEEDBACK_DELETE" /></td>
                          <td><input type="Checkbox" id="feedbackview" name="green" value="PUBLIC_FEEDBACK_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>Notification</td>
                          <td><input type="Checkbox" id="notificationadd" name="blue" value="APP_NOTIFICATION_ADD" /></td>
                          <td><input type="Checkbox" id="notificationedit" name="red" value="APP_NOTIFICATION_EDIT" /></td>
                          <td><input type="Checkbox" id="notificationdelete" name="green" value="APP_NOTIFICATION_DELETE" /></td>
                          <td><input type="Checkbox" id="notificationview" name="green" value="APP_NOTIFICATION_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>Config</td>
                          <td><input type="Checkbox" name="blue" id="configadd" value="CONFIG_ADD" /></td>
                          <td><input type="Checkbox" name="red" id="configedit" value="CONFIG_EDIT" /></td>
                          <td><input type="Checkbox" name="green" id="configdelete" value="CONFIG_DELETE" /></td>
                          <td><input type="Checkbox" name="green" id="configview" value="CONFIG_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>SOS</td>
                          <td><input type="Checkbox" name="blue" id="sosadd" value="SOS_ADD" /></td>
                          <td><input type="Checkbox" name="red" id="sosedit" value="SOS_EDIT" /></td>
                          <td><input type="Checkbox" name="green" id="sosdelete" value="SOS_DELETE" /></td>
                          <td><input type="Checkbox" name="green" id="sosview" value="SOS_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>Route Request</td>
                          <td><input type="Checkbox" name="blue" id="routerequestadd" value="ROUTE_REQUEST_ADD" /></td>
                          <td><input type="Checkbox" name="red" id="routerequestedit" value="ROUTE_REQUEST_EDIT" /></td>
                          <td><input type="Checkbox" name="green" id="routerequestdelete" value="ROUTE_REQUEST_DELETE" /></td>
                          <td><input type="Checkbox" name="green" id="routerequestview" value="ROUTE_REQUEST_VIEW" /></td>
                        </tr>
                        <tr>
                          <td>Place</td>
                          <td><input type="Checkbox" name="blue" id="placeadd" value="PLACE_ADD" /></td>
                          <td><input type="Checkbox" name="red" id="placeedit" value="PLACE_EDIT" /></td>
                          <td><input type="Checkbox" name="green" id="placedelete" value="PLACE_DELETE" /></td>
                          <td><input type="Checkbox" name="green" id="placeview" value="PLACE_VIEW" /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit">Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

RoleModal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

RoleModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(RoleModal);
