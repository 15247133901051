export default {
  getAppUserData: () => new Promise((resolve) => {
    const appUserData = {
      data: [], // array for your app configurations
    };
    setTimeout(() => {
      resolve({ data: appUserData });
    }, 1000);
  }),
};
