import React, { useMemo, useState, useEffect } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import Panel from '@/shared/components/Panel';
import OccupancyTooltipContent from './OccupancyTooltipContent';
import configs from '../../../../config/config';

const dataG = [];
const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

const Occupancy = ({ dir, themeName }) => {
  const { t } = useTranslation('common');
  const [data, setdata] = useState({ });

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}dashboard/getappusagelinegraph`)
      .then((response) => {
        setdata(response.data);
      });
  }, []);

  return (
    <Panel
      xl={12}
      lg={12}
      md={12}
      title={t('User active status')}
    >
      <div dir="ltr">
        <ResponsiveContainer height={260}>
          <ComposedChart
            data={data}
            margin={{
              top: 20,
              left: -15,
            }}
          >
            <XAxis dataKey="name" tickLine={false} padding={{ left: 20 }} reversed={dir === 'rtl'} />
            <YAxis tickLine={false} tickFormat={toPercent} orientation={dir === 'rtl' ? 'right' : 'left'} />
            <Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#959592' }} theme={themeName} />} />
            <CartesianGrid vertical={false} />
            <Bar dataKey="uv" name="Users count" fill="#787272" barSize={20} />
            <Line type="linear" name="Users" dataKey="departure" stroke="#192478" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <hr />
    </Panel>
  );
};

Occupancy.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(Occupancy);
