import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import UserDataTable from './components/UserDataTable';
import UserData from './components/UserData';

const User = () => {
  const userDataTable = UserData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">User Information</h3>
        </Col>
      </Row>
      <Row>
        <UserDataTable userDataTable={userDataTable} />
      </Row>
    </Container>
  );
};

export default User;
