import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap, MarkerClusterer, Marker, useJsApiLoader, LoadScript,
} from '@react-google-maps/api';
import Panel from '@/shared/components/Panel';
import data from './data.json';

const containerStyle = {
  height: '360px',
};

const BestSellingRegions = ({ datalat, datalng }) => {
  const { t } = useTranslation('common');

  const center = {
    lat: datalat,
    lng: datalng,
  };

  const locations = data.photos.map(marker => ({
    lat: datalat,
    lng: datalng,
  }));

  const createKey = (location, index) => location.lat + location.lng + index;

  useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAEDCY5nwtcaNJNvmBm9hlfEp3729At5JE',
  });

  return (
    <Panel xs={12} md={12} lg={12} xl={12} title={t('Location')}>
      <div dir="ltr">
        <LoadScript
          googleMapsApiKey="AIzaSyAEDCY5nwtcaNJNvmBm9hlfEp3729At5JE"
        >
          <GoogleMap
            id="bestSellingRegionsMap"
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            defaultCenter={{ lat: datalat, lng: datalng }}
          >
            <MarkerClusterer
              options={data.photos}
            >
              {cluster => locations.map((location, index) => (
                <Marker key={createKey(location, index)} position={{ lat: datalat, lng: datalng }} />
              ))}
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
      </div>
    </Panel>
  );
};

BestSellingRegions.propTypes = {
  datalat: PropTypes.number.isRequired,
  datalng: PropTypes.number.isRequired,
};

export default BestSellingRegions;
