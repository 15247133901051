import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
                          onClick, changeToLight, changeToDark, sidebarCollapse,
                        }) => {
  const hideSidebar = () => {
    onClick();
  };

  const history = useHistory();

  function setLocalId() {
    console.log('dvhgshghjsx');
    localStorage.setItem('id', '');
    history.push('/');
  }

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Dashboard" icon="home" route="/transit_dashboard" onClick={hideSidebar} />
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory title="User Management" icon="user" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Users" route="/usermanagement/user" onClick={hideSidebar} />
          <SidebarLink title="Roles" route="/usermanagement/role" onClick={hideSidebar} />
        </SidebarCategory>

        <SidebarCategory title="App Settings" icon="cog" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Config" route="/usermanagement/config" onClick={hideSidebar} />
        </SidebarCategory>
        <SidebarCategory title="Others" icon="alarm" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Notifications" route="/usermanagement/notification" onClick={hideSidebar} />
          <SidebarLink title="Feedback" route="/usermanagement/feedback" onClick={hideSidebar} />
          <SidebarLink title="Places" route="/usermanagement/place" onClick={hideSidebar} />
          <SidebarLink title="Route Requests" route="/usermanagement/routerequest" onClick={hideSidebar} />
          <SidebarLink title="SOS" route="/usermanagement/sos" onClick={hideSidebar} />
        </SidebarCategory>

      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" onClick={setLocalId} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
