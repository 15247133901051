import React, { useMemo, useState, useEffect } from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import Axios from 'axios';
import configs from '../../../../config/config';

const TotalCustomers = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}dashboard/usercount`)
      .then((response) => {
        setdata(response.data.userCount);
      });
  }, []);

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
              {data}
            </h5>
            <TrendingUpIcon className="dashboard__trend-icon" />
          </div>
          <h5 className="dashboard__booking-total-description">Total users count</h5>
          <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">.</p>
            <Progress value={79} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalCustomers;
