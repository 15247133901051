// export default {
//   appId': YOUR_APP_ID,
//   secretApp: Y
const configs = {
  development: {
    SERVER_URI: 'http://localhost:8080/',
  },
  production: {
    SERVER_URI: 'https://transit.capulustech.com/api/',
  },
};

module.exports.config = configs[process.env.NODE_ENV];
