import React, { useMemo, useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { ButtonToolbar, Row } from 'reactstrap';
import configs from '../../../config/config';

const UserData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}user/getusers`)
      .then((result) => {
          setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
      },
      {
        Header: 'Username',
        id: 'username',
        accessor: d => d.username,
      },
      {
        Header: 'Phone',
        id: 'phone',
        accessor: d => d.phone,
        disableGlobalFilter: true,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        disableGlobalFilter: true,
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: d => d.address,
        disableGlobalFilter: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );


  const datalist = [];

  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        name: data[i].name,
        username: data[i].username,
        phone: data[i].phone,
        email: data[i].email,
        address: data[i].address,
        action: [
          <Row>
            <ButtonToolbar>
              <Link to={`/usermanagement/profile/${data[i].id}`} className="btn btn-primary">Profile</Link>
            </ButtonToolbar>
          </Row>],
      });
    }
  };

  rows();
  const userDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return userDataTable;
};
export default UserData;


