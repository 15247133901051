import React, { useMemo, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import RoleEditModal from './RoleEditModal';
import configs from '../../../config/config';

// let tmpArray = [];

const RoleData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}role/getrole`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        id: 'nameconfig',
        accessor: d => d.nameconfig,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const datalist = [];

  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        nameconfig: data[i].name,
        action: [
          <Row>
            <Modal
              color="danger"
              title="Delete"
              btn="Delete"
              message="Are you sure you want to delete this"
              data={data[i].id}
            />
          </Row>],
      });
    }
  };

  rows();
  const roleDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return roleDataTable;
};
export default RoleData;
