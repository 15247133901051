import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PlaceDataTable from './components/PlaceDataTable';
import PlaceData from './components/PlaceData';

const Place = () => {
  const placeDataTable = PlaceData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Place Information</h3>
        </Col>
      </Row>
      <Row>
        <PlaceDataTable placeDataTable={placeDataTable} />
      </Row>
    </Container>
  );
};

export default Place;
