import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import EyeIcon from 'mdi-react/EyeIcon';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const UserModal = ({
                     color, btn, title, message, colored, header, rtl, name, className,
                     handleSubmit,
                   }) => {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(true);

  const handleShow = () => setShow(false);
  const history = useHistory();

  const toggle = () => {
    setModal(prevState => !prevState);
    setShow(prevState => !prevState);
  };
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const [user, setuser] = useState({
    Name: '',
    Role: '',
    Username: '',
    Password: '',
    ProfilePic: '',
    Imei: '',
    Address: '',
    Email: '',
    Note: '',
    Phone: '',
    UserType: '',
    value: '',
  });

  const onChange = (e) => {
    setuser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const radioButtonsGroup = [
    {
      name: 'isWebUser',
      label: 'Web User',
      radioValue: '1',
      defaultChecked: true,
    }, {
      name: 'isWebUser',
      label: 'Mobile User',
      radioValue: '2',
    },
  ];

  const options = [
    {
      value: 'ADMIN',
      label: 'ADMIN',
    },
    {
      value: 'USER',
      label: 'USER',
    },
  ];

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });


  function swalErrorFunction() {
    Swal.fire(
      'Error',
      'Please check the user information :(',
      'error',
    );
  }

  function onSubmit() {
    const formData = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    if (imagedata != null) {
      formData.append('profilepic', imagedata);
    }
    formData.append('role', document.getElementById('roleselect').value);
    formData.append('name', user.name);
    formData.append('username', user.username);
    formData.append('phone', user.phone);
    formData.append('email', user.email);
    formData.append('address', user.address);
    formData.append('imei', user.imei);
    formData.append('note', user.note);
    formData.append('isWebUser', user.isWebUser);
    formData.append('password', user.password);
    axios.post(`${configs.config.SERVER_URI}user/add`, formData)
      .then((response) => {
        Swal.fire({
          title: 'Success',
          text: 'User Added successfully :)',
          icon: 'success',
        });
        window.location.reload();
      })
      .catch(swalErrorFunction);
  }

  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color="primary" onClick={toggle}>{title}</Button>
          </Col>
          <Modal
            show={show}
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-user"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Name"
                        onChange={onChange}
                        value={user.Name}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Username</span>
                    <div className="form__form-group-field">
                      <Field
                        name="username"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Username"
                        onChange={onChange}
                        value={user.Username}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Password</span>
                    <div className="form__form-group-field">
                      <Field
                        name="password"
                        className="form-control"
                        component="input"
                        type={isPasswordShown ? 'text' : 'password'}
                        placeholder="Password"
                        onChange={onChange}
                        value={user.Password}
                        required
                      />
                      <button
                        type="button"
                        className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                        onClick={showPassword}
                      ><EyeIcon />
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Phone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phone"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Phone Number"
                        onChange={onChange}
                        value={user.Phone}
                        required
                      />
                    </div>
                  </div>
                </Col>

                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Role</span>
                    <div className="form__form-group-field">
                      <select id="roleselect" className="form-control" required style={{ width: '600px' }}>
                        <option value="ADMIN">ADMIN</option>
                        <option value="USER">USER</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">E-mail</span>
                    <div className="form__form-group-field">
                      <Field
                        name="email"
                        className="form-control"
                        component="input"
                        type="email"
                        placeholder="example@mail.com"
                        onChange={onChange}
                        value={user.Email}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">IMEI</span>
                    <div className="form__form-group-field">
                      <Field
                        name="imei"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter IMEI"
                        onChange={onChange}
                        value={user.Imei}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address"
                        className="form-control"
                        component="textarea"
                        type="text"
                        placeholder="Enter Address"
                        onChange={onChange}
                        value={user.Address}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Note</span>
                    <div className="form__form-group-field">
                      <Field
                        name="note"
                        className="form-control"
                        component="textarea"
                        type="text"
                        placeholder="Enter Note"
                        onChange={onChange}
                        value={user.Note}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Upload photo</span>
                    <div className="form__form-group-field">
                      <Field
                        id="profilepic"
                        name="profilepic"
                        type="file"
                        component={renderDropZoneField}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit" onClick={toggle}>Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

UserModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

UserModal.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(UserModal);
