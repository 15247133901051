import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import FeedbackDataTable from './components/FeedbackDataTable';
import FeedbackData from './components/FeedbackData';

const Feedback = () => {
  const feedbackDataTable = FeedbackData();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Feedback Information</h3>
        </Col>
      </Row>
      <Row>
        <FeedbackDataTable feedbackDataTable={feedbackDataTable} />
      </Row>
    </Container>
  );
};

export default Feedback;
