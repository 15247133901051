import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'reactstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';


const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const NotificationDataTable = ({ notificationDataTable }) => {
  const [rows, setData] = useState(notificationDataTable.tableRowsData);
  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsEditable = () => {
    if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setIsEditable(!isEditable);
  };
  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by title...',
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <div className="react-table__wrapper">
        <ReactTableCustomizer
          handleClickIsEditable={handleClickIsEditable}
          handleClickIsResizable={handleClickIsResizable}
          handleClickIsSortable={handleClickIsSortable}
          handleClickWithDragAndDrop={handleClickWithDragAndDrop}
          handleClickWithPagination={handleClickWithPagination}
          handleClickWithSearchEngine={handleClickWithSearchEngine}
          isEditable={isEditable}
          isResizable={isResizable}
          isSortable={isSortable}
          isDisabledDragAndDrop={isDisabledDragAndDrop}
          isDisabledEditable={isDisabledEditable}
          isDisabledResizable={isDisabledResizable}
          withDragAndDrop={withDragAndDrop}
          withPagination={withPagination}
          withSearchEngine={withSearchEngine}
          fullCustomizer
        />
      </div>
      <ReactTableBase
        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
        columns={notificationDataTable.tableHeaderData}
        data={notificationDataTable.tableRowsData}
        updateEditableData={updateEditableData}
        updateDraggableData={updateDraggableData}
        tableConfig={tableConfig}
      />
    </Col>
  );
};

NotificationDataTable.propTypes = {
  notificationDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default NotificationDataTable;
