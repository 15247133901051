import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { checkboxGroup } from './CheckFormControlsData';

const CheckBoxGroup = ({ name, className }) => (
  <input type="checkbox" />
);

CheckBoxGroup.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

CheckBoxGroup.defaultProps = {
  name: '',
  className: '',
};

export default CheckBoxGroup;
