import React, { useMemo, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import NotificationEditModal from './NotificationEditModal';
import configs from '../../../config/config';

const NotificationData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${configs.config.SERVER_URI}appnotification/get`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Title',
        id: 'title',
        accessor: d => d.title,
      },
      {
        Header: 'Message',
        id: 'message',
        accessor: d => d.message,
        disableGlobalFilter: true,
      },
      {
        Header: 'NotificationType',
        id: 'notificationType',
        accessor: d => d.notificationType,
        disableGlobalFilter: true,
      },
      {
        Header: 'ReceiverType',
        id: 'receiverType',
        accessor: d => d.receiverType,
        disableGlobalFilter: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const datalist = [];

  const rows = () => {
    for (let i = 0; i < data.length; i += 1) {
      datalist.push({
        id: i + 1,
        title: data[i].title,
        message: data[i].message,
        notificationType: data[i].notificationType,
        receiverType: data[i].receiverType,
        action: [
          <Row>
            <NotificationEditModal
              btncolor="warning"
              color="primary"
              btntitle="Edit"
              title="Edit Notification"
              header
              btn="primary"
              dataid={data[i].id}
              datatitle={data[i].title}
              datamessage={data[i].message}
              datareceiverType={data[i].receiverType}
              dataphoto={data[i].photo}
            />
            <Modal
              color="danger"
              title="Delete"
              btn="Delete"
              message="Are you sure you want to delete this"
              data={data[i].id}
            />
          </Row>],
      });
    }
  };

  rows();
  const notificationDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return notificationDataTable;
};
export default NotificationData;


