import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import renderDropZoneField from '@/shared/components/form/DropZone';
import MaskedInput from 'react-text-mask';
import axios from 'axios/index';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const renderNotificationField = ({
                                   input, type, placeholder, value,
                                 }) => (
                                   <MaskedInput {...input} placeholder={placeholder} type={type} value={value} />
);

renderNotificationField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

renderNotificationField.defaultProps = {
  placeholder: '',
  type: 'text',
};

const NotificationEditForm = ({
                                color, btncolor, btn, title, btntitle, colored, header, name, className,
                                handleSubmit, dataid, datatitle, datamessage, datareceiverType, dataphoto,
                              }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  const [notification, setnotification] = useState({
    title: datatitle,
    message: datamessage,
    receiverType: datareceiverType,
    photo: dataphoto,
    preview: `${configs.config.SERVER_URI}api/media/notification/GENERAL/${dataphoto}`,
  });

  function swalFunction() {
    Swal.fire({
      icon: 'success',
      title: 'Success....',
      text: 'Notification Updated Successfully',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
  }

  function submitFunction() {
    const formData = new FormData();
    const imagedata = document.querySelectorAll('input[type="file"]')[1].files[0];
    if (imagedata != null) {
      formData.append('photo', imagedata);
    }
    console.log(imagedata);
    formData.append('id', dataid);
    formData.append('title', notification.title);
    formData.append('message', notification.message);
    formData.append('receiverType', notification.receiverType);

    axios.post(`${configs.config.SERVER_URI}appnotification/update`, formData)
      .then((response) => {
        swalFunction();
      })
      .catch(error => console.log(error));
  }

  let Icon;

  switch ([color, btncolor]) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });
  const onChange = (e) => {
    setnotification({
      ...notification,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color={btncolor} onClick={toggle}>{btntitle}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-notification"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <input
                        name="title"
                        className="form-control"
                        component={renderNotificationField}
                        type="text"
                        value={notification.title}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Message</span>
                    <div className="form__form-group-field">
                      <textarea
                        name="message"
                        className="form-control"
                        type="text"
                        value={notification.message}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Photo</span>
                    <div className="form__form-group-field">
                      <img src={notification.preview} alt="" className="notification-image" />
                      <Field
                        id="photo"
                        name="photo"
                        type="file"
                        className="notification-image"
                        component={renderDropZoneField}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit" onClick={submitFunction}>Send</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

NotificationEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  btntitle: PropTypes.string,
  color: PropTypes.string.isRequired,
  btncolor: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  dataid: PropTypes.string.isRequired,
  datatitle: PropTypes.string.isRequired,
  datamessage: PropTypes.string.isRequired,
  datareceiverType: PropTypes.string,
  dataphoto: PropTypes.string,
};

NotificationEditForm.defaultProps = {
  title: '',
  btntitle: '',
  colored: false,
  header: false,
  name: '',
  className: '',
  datareceiverType: '',
  dataphoto: '',
};

const notificationEditForm = reduxForm({
  enableReinitialize: true,
  form: 'horizontal_form',
})(NotificationEditForm);

const mapStateToProps = state => ({
  initialValues: state.cryptoTable.data,
});

export default connect(mapStateToProps)(notificationEditForm);
