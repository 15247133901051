import React from 'react';
import {
  Col, Container, Row, Card, CardBody,
} from 'reactstrap';
import NotificationDataTable from './components/NotificationDataTable';
import NotificationData from './components/NotificationData';
import NotificationModal from './components/NotificationModal';


const Notification = () => {
  const notificationDataTable = NotificationData();
  return (
    <Container>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <h3 className="page-title">&nbsp;&nbsp;Send Notification</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <NotificationModal />
          <br />
          <h3 className="page-title">Notifications History</h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md={12} lg={12} xl={12}>
              <NotificationDataTable notificationDataTable={notificationDataTable} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Notification;
