import React, { useMemo, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import Axios from 'axios';
import Modal from '@/shared/components/Modal';
import ConfigEditModal from './ConfigEditModal';
import configs from '../../../config/config';

const ConfigData = () => {
  const [data, setData] = useState([]);

  const getAllConfig = () => {
    Axios
      .get(`${configs.config.SERVER_URI}config/getconfig`)
      .then((result) => {
        setData(result.data);
        console.log(data);
      });
  };

  useEffect(() => {
    getAllConfig();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Name',
        id: 'nameconfig',
        accessor: d => d.nameconfig,
      },
      {
        Header: 'Value',
        id: 'value',
        accessor: d => d.value,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const datalist = [];

  const rows = () => {
    for (let i = 1; i < data.length; i += 1) {
      datalist.push({
        id: i,
        nameconfig: data[i].name,
        value: data[i].value,
        action: [
          <Row>
            <ConfigEditModal
              btncolor="warning"
              color="primary"
              btntitle="Edit"
              title="Edit Config"
              header
              btn="primary"
              dataid={data[i].id}
              dataname={data[i].name}
              datavalue={data[i].value}
              getAllConfig={getAllConfig}
            />
            <Modal
              color="danger"
              title="Delete"
              btn="Delete"
              message="Are you sure you want to delete this"
              data={data[i].id}
              getAllConfig={getAllConfig}
            />
          </Row>],
      });
    }
  };

  rows();
  const configDataTable = {
    tableHeaderData: columns,
    tableRowsData: datalist,
  };
  return configDataTable;
};
export default ConfigData;
