/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import TextField from '@material-ui/core/TextField';
import axios from 'axios/index';
import Swal from 'sweetalert2';
import configs from '../../../../config/config';

const renderTextField = ({
                           input, label, meta: { touched, error }, children,
                         }) => (
                           <TextField
                             className="material-form__field"
                             label={label}
                             error={touched && error}
                             children={children}
                             value={input.value}
                           />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
};

const ProfilePassword = ({
                           handleSubmit, reset, userid,
                         }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [userpassword, setuserpassword] = useState({
    repeatpassword: '',
    newpassword: '',
    old_password: '',
  });

  const onChange = (e) => {
    setuserpassword({
      ...userpassword,
      [e.target.name]: e.target.value,
    });
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const showRepeatPasswordToggle = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const showCurrentPasswordToggle = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  function swalErrorFunction() {
    Swal.fire(
      'Error',
      'Please Re-Enter Correct Confirm Password',
      'error',
    );
  }

  function swalErrorOldFunction() {
    Swal.fire(
      'Error',
      'Old Password and new Password Can not be same',
      'error',
    );
  }

  function swalLengthErrorFunction() {
    Swal.fire(
      'Error',
      'Minimum length of the password must be 8',
      'error',
    );
  }

  function onPasswordSubmit(e) {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append('id', userid);
    params.append('repeatpassword', userpassword.repeatpassword);
    params.append('newpassword', userpassword.newpassword);
    params.append('old_password', userpassword.old_password);
    if (userpassword.repeatpassword && userpassword.newpassword >= 8) {
      if (userpassword.newpassword === userpassword.repeatpassword) {
        if (userpassword.old_password !== userpassword.repeatpassword) {
          axios.post(`${configs.config.SERVER_URI}user/updatepassword`, params)
            .then((result) => {
              Swal.fire(
                'Success',
                'Password updated successfully :)',
                'success',
              );
              setuserpassword({
                ...userpassword,
                newpassword: '',
                repeatpassword: '',
                old_password: '',
              });
            })
            .catch(error => console.log(error));
        } else {
          swalErrorOldFunction();
          setuserpassword({
            ...userpassword,
            newpassword: '',
            repeatpassword: '',
            old_password: '',
          });
        }
      } else {
        swalErrorFunction();
        setuserpassword({
          ...userpassword,
          newpassword: '',
          repeatpassword: '',
          old_password: '',
        });
      }
    } else {
      swalLengthErrorFunction();
      setuserpassword({
        ...userpassword,
        newpassword: '',
        repeatpassword: '',
        old_password: '',
      });
    }
  }

  return (
    <form className="material-form" onSubmit={onPasswordSubmit} autoComplete="off">
      <div>
        <div className="form__form-group">
          <span className="form__form-group-label">Current Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="old_password"
              className="old_password"
              component="input"
              type={showCurrentPassword ? 'text' : 'password'}
              placeholder="Current Password"
              id="input-without-border-radius-password"
              onChange={onChange}
              value={userpassword.old_password}
              required
            />
            <button
              type="button"
              className={`form__form-group-button${showCurrentPassword ? ' active' : ''}`}
              onClick={showCurrentPasswordToggle}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">New Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="newpassword"
              className="newpassword"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              id="input-without-border-radius-password"
              onChange={onChange}
              value={userpassword.newpassword}
              required
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={showPasswordToggle}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="repeatpassword"
              className="repeatpassword"
              component="input"
              type={showRepeatPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              id="input-without-border-radius-password"
              onChange={onChange}
              value={userpassword.repeatpassword}
              required
            />
            <button
              type="button"
              className={`form__form-group-button${showRepeatPassword ? ' active' : ''}`}
              onClick={showRepeatPasswordToggle}
            ><EyeIcon />
            </button>
          </div>
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar float-right">
        <Button color="primary" type="submit" onClick={onPasswordSubmit}>Update</Button>
      </ButtonToolbar>
    </form>
  );
};


ProfilePassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  userid: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'profile_settings_form', // a unique identifier for this form
})(ProfilePassword);
