import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2/dist/sweetalert2.all.min';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Field, reduxForm } from 'redux-form';
import {
  Button, ButtonToolbar, Modal, Row, Col,
} from 'reactstrap';
import classNames from 'classnames';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import axios from 'axios';
import Swal from 'sweetalert2';
import configs from '../../../config/config';

const PlaceModal = ({
                      color, btn, title, colored, header, rtl, name, className,
                      handleSubmit,
                    }) => {
  const [modal, setModal] = useState(false);

  const [place, setplace] = useState({
    name: '',
    address: '',
    lat: '',
    lng: '',
    description: '',
    stop_id: '',
    stop_name: '',
    route_short_name: '',
    photos: '',
  });


  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,

  });

  function HandleError() {
    swal({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Failed to Add Place</a>',
    });
  }

  function swalFunction() {
    Swal.fire({
      icon: 'success',
      title: 'Success....',
      text: 'Notification successfully added',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
          window.location.reload();
      }
    });
  }

  function onSubmit() {
    const formData = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    if (imagedata != null) {
      formData.append('photos', imagedata);
    }
    formData.append('name', place.name);
    formData.append('address', place.address);
    formData.append('lat', place.lat);
    formData.append('lng', place.lng);
    formData.append('description', place.description);
    formData.append('stop_id', place.stop_id);
    formData.append('stop_name', place.stop_name);
    formData.append('route_short_name', place.route_short_name);
    axios.post(`${configs.config.SERVER_URI}place/add`, formData)
      .then((response) => {
          window.location.reload();
      })
      .catch(error => console.log(error));
  }

  const onChange = (e) => {
    setplace({
      ...place,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row>
      <Col md={12} lg={12} xl={12}>
        <div>
          <Col md={12} lg={12} xl={12}>
            <Button color="primary" onClick={toggle}>{title}</Button>
          </Col>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--${color} ${modalClass} ${className}`}
            id="modal-dialog-feedback"
          >
            <div className="modal__header">
              <button
                className="lnr lnr-cross modal__close-btn text-lg-right"
                aria-label="modal__close-btn"
                type="button"
                onClick={toggle}
              />
              {header ? '' : Icon}
              <h4 className="text-modal  modal__title">{title}</h4>
            </div>
            <div className="modal__body">
              <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Name"
                        onChange={onChange}
                        value={place.name}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Latitude</span>
                    <div className="form__form-group-field">
                      <Field
                        name="lat"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Latitude"
                        onChange={onChange}
                        value={place.lat}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Longitude</span>
                    <div className="form__form-group-field">
                      <Field
                        name="lng"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Longitude"
                        onChange={onChange}
                        value={place.lng}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Stop Id</span>
                    <div className="form__form-group-field">
                      <Field
                        name="stop_id"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Stop Id"
                        onChange={onChange}
                        value={place.stop_id}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Stop Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="stop_name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Stop Name"
                        onChange={onChange}
                        value={place.stop_name}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Route Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="route_short_name"
                        className="form-control"
                        component="input"
                        type="text"
                        placeholder="Enter Route Name"
                        onChange={onChange}
                        value={place.route_short_name}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address"
                        className="form-control"
                        component="textarea"
                        type="text"
                        onChange={onChange}
                        value={place.address}
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <Field
                        name="description"
                        className="form-control"
                        component="textarea"
                        type="text"
                        onChange={onChange}
                        value={place.description}
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Upload photo</span>
                    <div className="form__form-group-field">
                      <Field
                        id="photos"
                        name="photos"
                        type="file"
                        component={renderDropZoneField}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <ButtonToolbar className="modal__footer float-right">
                    &nbsp;&nbsp;<Button type="button" onClick={toggle}>Cancel</Button>&nbsp;&nbsp;&nbsp;
                    <Button color="primary" type="submit">Submit</Button>
                  </ButtonToolbar>
                </Col>
              </form>
            </div>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

PlaceModal.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

PlaceModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  name: '',
  className: '',
};


export default reduxForm({
  form: 'vertical_form', // a unique identifier for this form
})(PlaceModal);
